import React from 'react'
import Footer from 'components/Footer'
import Seo from 'components/Seo'
import BlockBanner from 'components/blocks/BlockBanner'
import BlockTextImage from 'components/blocks/BlockTextImage'
import BlockBannerMid from 'components/blocks/BlockBannerMid'
import { content, filterContentByLocale } from 'content/content.js'
import Inview from 'components/Inview'
import { useLocalization } from 'gatsby-theme-i18n'

const AboutPage = () => {
  const { locale } = useLocalization()
  const localeContent = filterContentByLocale(content, locale)
  const { about, footer } = localeContent
  const { banner, textImage, bannerMid, textImage2 } = about

  let seoContent = {
    title: {
      en: `About`,
      zh: `关于我们`,
    },
  }
  seoContent = filterContentByLocale(seoContent, locale)

  return (
    <div>
      <div className='page'>
        <Seo content={seoContent} />
        <div className='bg-gradient'>
          <BlockBanner content={banner} />
          <BlockTextImage
            content={textImage}
            isMobileImageFirst={true}
            isOpacity75={true}
          />
          <BlockBannerMid content={bannerMid} isBlueTitle={true} />
          <BlockTextImage
            content={textImage2}
            isMobileImageFirst={true}
            isOpacity75={true}
          />
          <Footer content={footer.default} />
        </div>
      </div>
    </div>
  )
}

export default AboutPage
