import React from 'react'
import Image from 'components/Image'
import Inview from 'components/Inview'

const BlockBannerMid = ({ content, isBlueTitle, isShorterMobile }) => {
  const { image, imageMobile, title, subtitle } = content
  return (
    <section className='block-banner-mid'>
      <Inview className={`inner ${isShorterMobile ? 'shorter-mobile' : ''}`}>
        <div className='image image-desktop fade-in up'>
          <Image filename={image} />
        </div>
        <div className='image image-mobile fade-in up'>
          <Image filename={imageMobile} />
        </div>
        <div className='text fade-in stagger-500'>
          <div className='wrap'>
            <h1 className={isBlueTitle ? 'blue' : ''}>{title}</h1>
            <h2>{subtitle}</h2>
          </div>
        </div>
      </Inview>
    </section>
  )
}

export default BlockBannerMid
